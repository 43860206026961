import { graphql } from 'gatsby'
import React, { useEffect, useState } from 'react'

import Gallery from '@browniebroke/gatsby-image-gallery'
import '@browniebroke/gatsby-image-gallery/dist/style.css'

import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Slider from "react-slick"
import Img from "gatsby-image"
import img1 from "../../assets/img/facade/img-1.jpg"
import img2 from "../../assets/img/facade/img-2.jpg"
import img3 from "../../assets/img/facade/img-3.jpg"
import catalogue from "../../assets/img/facade/catalogue.jpg"
import catalogueFile from "../../assets/pdf/catalogue-facade.pdf"

const FacadePage = ({ data }) => {
  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const [slider1, setSlider1] = useState(null);
  const [slider2, setSlider2] = useState(null);

  useEffect(() => {
    setNav1(slider1);
    setNav2(slider2);
  },
  [slider1, slider2]
  );
  const settingsMain = {
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    fade: true,
    asNavFor: '.slider-nav'
  };
  const settingsThumbs = {
    slidesToShow: 4,
    slidesToScroll: 1,
    asNavFor: '.slider-for',
    dots: false,
    centerMode: true,
    swipeToSlide: true,
    focusOnSelect: true,
    centerPadding: '10px',
    arrows: false,
  };
  const g = data.gallery.edges.map(({ node }) => node.childImageSharp)
 return(
  <Layout>
    <SEO title="Façade" />
    <div class="w-full relative">
            <div className="slider-wrapper">

              <Slider  {...settingsMain}  asNavFor={nav2} ref={slider => (setSlider1(slider))}>
              { data.sld.edges.map(({ node, index }) =>
                  <div className="slick-slide" key={index}>
                      <Img className="slick-slide-image" fluid={node.childImageSharp.full} />
                  </div>
                )}
              </Slider>
            </div>
            <div className="thumbnail-slider-wrap absolute left-0 right-0 bottom-0 mx-auto w-1/4">

                <Slider {...settingsThumbs} asNavFor={nav1} ref={slider => (setSlider2(slider))}>
                    { data.sld.edges.map(({ node, index }) =>
                      <div className="slick-slide slick-slide-thumb" key={index}>
                          <Img className="slick-slide-image rounded-full cursor-pointer" fluid={node.childImageSharp.thumb} />
                      </div>
                    )}
                </Slider>
            </div>
        </div>
   <div class="grid grid-cols-1 mt-8 gap-5 md:mx-40 md:my-5 mx-3">
     <h1 class="text-center text-2xl font-bold uppercase">Façade</h1>
     <div class="bg-gradient-to-r from-white via-red-700 to-white h-0.5"></div>
   </div>
   <div class="grid grid-cols-1 md:grid-cols-2 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
       <div>  <img src={img1} alt="image 1" /> </div>
       <div>  <img src={img2} alt="image 2" /> </div>
   </div>
   <div class="grid grid-cols-1 md:grid-cols-3 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
   <div><img src={img3} alt="image 3" /></div>
   <div class="col-span-1 md:col-span-2">
       <p class="mb-3 text-justify"> Une façade est la « peau » qui protège une habitation et contribue à sa beauté. Résistant aux intempéries et aux UV, sans joints apparents, offrant des décors et des finitions variées, le corian permet de réaliser des façades très Creativees et d’une esthétique exceptionnelle.</p>

       <p class="mb-3 text-justify"> Avec ce matériau, les designers et les architectes peuvent désormais créer des volumes innovants et durables, aux lignes pures. Personnalisez votre environnement à l’image de votre enseigne grâce aux gravures, aux sublimations et aux associations de couleurs.</p>

       <p class="text-justify"> Une façade en Solid Surface vous permet d’exprimer votre créativité grâce à des techniques et des possibilités qui associent la longévité et la résistance d’un matériau homogène en panneau avec les bénéfices prouvés d’une façade ventilée.</p>
   </div>
   </div>
   <div class="grid grid-cols-1 md:grid-cols-3 mt-8 gap-8 md:mx-40 md:my-5 mx-3">
        <div class="col-span-1 md:col-span-2">
            <h3 class="font-bold text-lg mb-2">Des façades qui interpellent les sens</h3>
            <p class="text-justify">Le revêtement de surface Solid Surface est un exemple significatif de cette évolution. Créé comme une alternative haute performance aux matériaux de revêtement conventionnels, il s’est imposé aujourd’hui comme une solution esthétique et polyvalente pour les façades de bâtiments. aussi bien utilisée à l’intérieur qu’à l’extérieur, à grande ou petite échelle,dans des esthétiques minimales ou avec des effets décoratifs, de New York à Moscou, de Pékin à Rome, de Paris à Istanbul.
            Conçu à partir d’une expertise mondiale en science et technologie et dans l’héritage d’une innovation unique, ce matériau reflète aujourd’hui l’esprit d’une évolution toujours en mouvement.</p>
            <h3 class="font-bold text-lg mt-4 mb-2">Une solution extérieure unique qui associe forme et fonction</h3>
            <p class="text-justify">Très esthétique, Solid Surface External Cladding permet une liberté de conception extraordinaire et garantit une fiabilité et une résistance hors pair dans le temps ; il est le choix idéal pour les panneaux de façades. Solide, homogène et coloré dans la masse, Solid Surface EC se présente en panneaux sur mesure, permettant de créer de grandes surfaces verticales aux joints imperceptibles. Des options décoratives sont aussi possibles, effets 3D texturés, décors en relief, courbes, inserts et effets lumineux étonnants.</p>
        </div>
        <div> 
          <p class="mb-3 font-bold text-center">Télécharger notre catalogue</p>
           <a href={catalogueFile} class="relative block hover-trigger" download>
              <img src={catalogue} alt="cataloge façade" class="my-0" />
              <div class="absolute left-0 right-0 text-center bottom-5 bg-white border border-grey-100 px-4 py-2 hover-target">
                Télécharger
              </div>
            </a>
        </div>
   </div>
    <div class="grid grid-cols-1 mt-8 gap-8 md:mx-40 md:my-5 mx-3 gl">
          <Gallery images={g} />
    </div>
   </Layout>

)
}
export const query = graphql`
query FacadeGallery {
  gallery:allFile (filter:{relativeDirectory: {eq: "img/facade/g"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 270, maxHeight: 270) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
  sld:allFile (filter:{relativeDirectory: {eq: "img/facade/s"}}){
    edges {
      node {
        childImageSharp {
          thumb: fluid(maxWidth: 100) {
            ...GatsbyImageSharpFluid
          }
          full: fluid(maxWidth: 1024) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}
`
export default FacadePage
{/** https://github.com/browniebroke/gatsby-image-gallery */}